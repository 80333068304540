import { Document } from '@contentful/rich-text-types';
import { TypeOrientedMedia } from '../../../@types/generated';
import HighlightLayout, {
  HighlightButton,
} from '../../common-components/component-library/layout/HighlightLayout';
import { getVideoById, Video } from '../api/videoApi';

export async function createHighlightVideo(video?: TypeOrientedMedia) {
  if (!video || !video.fields.videoIdLandscape) return;

  const [landscapeVideo, portraitVideo] = await Promise.all([
    getVideoById(video.fields.videoIdLandscape),
    getVideoById(video.fields.videoIdPortrait),
  ]);

  return { landscapeVideo, portraitVideo: portraitVideo ?? landscapeVideo };
}

export function createHighlightImage(image?: TypeOrientedMedia, imageFit?: 'cover' | 'contain') {
  if (!image) return;
  return {
    imageUrl:
      image.fields?.imageLandscape?.fields.file?.url ||
      image.fields.imagePortrait?.fields.file?.url,
    imageText: image?.fields.credit,
    objectFit: imageFit,
  };
}

export type HighlightProps = Partial<{
  sectionTitle: string;
  text: Document;
  buttons: Array<HighlightButton>;
  image: Partial<{
    imageUrl: string;
    imageText: string;
    objectFit?: 'cover' | 'contain';
  }>;
  landscapeVideo?: Video;
  portraitVideo?: Video;
  withCheckmark?: boolean;
}>;

export default function Highlight({
  sectionTitle,
  text,
  buttons,
  image,
  landscapeVideo,
  portraitVideo,
  rightAlignedCopy,
  backgroundColor,
  withCheckmark,
}: HighlightProps & { rightAlignedCopy?: boolean; backgroundColor?: string }) {
  return (
    <HighlightLayout
      id={sectionTitle}
      rightAlignedCopy={rightAlignedCopy}
      sectionTitle={sectionTitle}
      text={text}
      buttons={buttons}
      buttonVariant="text"
      images={image ? [image] : undefined}
      landscapeVideo={landscapeVideo}
      portraitVideo={portraitVideo}
      backgroundColor={backgroundColor}
      withCheckmark={withCheckmark}
    />
  );
}

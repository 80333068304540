import styled from '@emotion/styled';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import Divider from '../Divider';
import SectionTitle from '../SectionTitle';
import Typography from '../text/Typography';
import SquareMap from '../squareMap/SquareMap';
import Container from './Container';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[5]}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  gap: ${spacing[6]}px;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
  }
`;
const MapWrapper = styled.div`
  height: 100%;
`;

const MetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${spacing[6]}px;

  ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

const getMetricFlex = (metricCount: number, index: number) => {
  if (metricCount % 3 === 0) {
    return '1 0 25%';
  }
  if (metricCount === 2 || metricCount === 4) {
    return '1 0 45%';
  }
  if (metricCount === 1 || metricCount % 3 === 1) {
    return metricCount - index === 1 ? '0 0 50%' : '1 0 25%';
  }
  if (metricCount % 3 === 2) {
    return metricCount - index === 2 || metricCount - index === 1 ? '1 0 45%' : '1 0 25%';
  }
};

const Metric = styled.div<{ metricCount: number; index: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${breakpoints.tablet} {
    flex: ${({ metricCount, index }) => getMetricFlex(metricCount, index)};
  }
`;

type Props = {
  sectionTitle?: string;
  mapItems?: {
    label?: string;
    locationCodes: string[];
    disabled?: boolean;
  }[];
  metrics?: {
    metricValue?: string;
    metricName?: string;
    description?: string;
  }[];
  disableVerticalPadding?: boolean;
  id?: string;
};

export default function MapMetricsLayout({
  sectionTitle,
  mapItems,
  metrics,
  disableVerticalPadding,
  id,
}: Props) {
  if (!sectionTitle && !metrics?.length && !mapItems?.length) return null;
  const hasMapLocations = !!mapItems?.flatMap(({ locationCodes }) => locationCodes).length;

  return (
    <Container id={id} disableVerticalPadding={disableVerticalPadding}>
      <Wrapper>
        {sectionTitle && <SectionTitle title={sectionTitle} />}
        <ContentWrapper>
          {hasMapLocations ? (
            <MapWrapper>
              <SquareMap items={mapItems} />
            </MapWrapper>
          ) : null}
          {!!metrics?.length && (
            <MetricsWrapper>
              {metrics.map((fundMetric, index) => (
                <Metric key={index} metricCount={metrics.length} index={index}>
                  <Typography tag="h2" variant="h1">
                    {fundMetric.metricValue}
                  </Typography>
                  <Typography variant="overlineBold">{fundMetric.metricName}</Typography>
                  <Divider color={colors.black} />
                  <Typography variant="body">{fundMetric.description}</Typography>
                </Metric>
              ))}
            </MetricsWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}

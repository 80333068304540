import { Document } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TypeForm, TypeFormItem } from '../../../../@types/generated';
import { hubSpotSubmit } from '../../../utils/api/contentful';
import { prepareFormBody } from '../../../utils/helpers/formHandler';
import { useMediaQuery } from '../../../utils/hooks/useMediaQuery';
import { breakpointQueries, breakpoints, colors, spacing } from '../../../utils/styleguide';
import RichText from '../../contentful-elements/RichText/RichText';
import PrimaryButton from '../buttons/PrimaryButton';
import Divider from '../Divider';
import TextInput, { validations } from '../forms/TextInput';
import SectionTitle from '../SectionTitle';
import Typography from '../text/Typography';
import Container from './Container';
import { usePathname } from 'next/navigation';
import IconButton from '../buttons/IconButton';

export type FormSectionProps = Partial<{
  sectionTitle: string;
  title: string;
  description: Document;
  form: TypeForm;
  id?: string;
}>;

const ContentWrapper = styled.div`
  padding-top: ${spacing[6]}px;
  padding-left: 0;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
  }
`;

const Content = styled.div`
  ${breakpoints.desktop} {
    display: flex;
    flex-direction: row;
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${spacing[3]}px;
  justify-content: center;
`;

const CTA = styled(PrimaryButton)`
  margin-top: ${spacing[4]}px;
`;

const StyledDivider = styled(Divider)`
  margin: ${spacing[4]}px 0;
  ${breakpoints.desktop} {
    margin: 0 ${spacing[6]}px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
`;

const Input = styled(TextInput)`
  margin: ${spacing[2]}px 0;
  width: 100%;
  ${breakpoints.desktop} {
    margin-bottom: 0px;
    margin-right: ${spacing[3]}px;
    flex: 1;
  }
`;

const getInitialForm = (formItems: (TypeFormItem | undefined)[]): Record<string, string> => {
  return formItems
    .filter((item): item is TypeFormItem => !!item)
    .reduce((acc: Record<string, string>, formItem): Record<string, string> => {
      acc[formItem.fields.fieldName] = '';
      return acc;
    }, {});
};

const checkFormValid = (
  formItems: (TypeFormItem | undefined)[],
  updatedForm: Record<string, string>,
): boolean => {
  return formItems
    .filter((item): item is TypeFormItem => !!item)
    .every(({ fields: { fieldName, type, required } }: TypeFormItem): boolean => {
      const value = updatedForm[fieldName];
      let valid = true;
      if (value && type in validations) {
        // @ts-ignore FIXME
        valid = validations[type].every((validation) => validation.validate(value));
      }

      if (!valid) {
        return false;
      }

      if (required && !value) {
        return false;
      }

      return true;
    });
};

export default function FormSection({
  sectionTitle,
  title,
  description,
  form,
  id,
}: FormSectionProps) {
  const pathname = usePathname();
  const [formData, setFormData] = useState(getInitialForm(form?.fields.formItems ?? []));
  const [formValid, setFormValid] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isDesktop] = useMediaQuery(breakpointQueries.desktop);

  const contentForm = useMemo(() => {
    return form?.fields.formItems
      .filter((item): item is TypeFormItem => !!item)
      .map(({ fields: { fieldName, type, placeholder, required = false } }, index) => {
        return (
          <Input
            key={index}
            label={placeholder + (required ? '*' : '')}
            type={type}
            name={fieldName}
            multiline={fieldName === 'message'}
            value={formData[fieldName]}
            required={required}
            // @ts-ignore FIXME
            validations={type && validations[type]}
          />
        );
      });
  }, [form?.fields.formItems, formData]);

  const changeForm = (event: ChangeEvent<HTMLFormElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  async function hubspotSubmit() {
    if (form) {
      const submitMessage = await hubSpotSubmit({
        url: form.fields.url,
        body: prepareFormBody({ type: form.fields.type, formData }),
        successMessage: form.fields.successMessage ?? '',
        errorMessage: form.fields.errorMessage ?? '',
      });
      setFormData(getInitialForm(form.fields.formItems));
      setMessage(submitMessage);
    }
  }

  const onFormSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (form?.fields.type === 'hubspot') {
      hubspotSubmit();
    }
  };

  useEffect(() => {
    const isFormValid = checkFormValid(form?.fields.formItems ?? [], formData);
    if (isFormValid !== formValid) {
      setFormValid(isFormValid);
    }
  }, [formData, formValid, setFormValid, form?.fields.formItems]);

  useEffect(() => {
    setMessage(null);
    return () => {
      setMessage(null);
    };
  }, [pathname]);

  useEffect(() => {
    if (!form?.fields.url) {
      setMessage(
        'The form is currently unavailable, please try again later or reach out to us at hello@milkywire.com.',
      );
    }
  }, [form?.fields.url]);

  if (!(form && title && description)) {
    return null;
  }

  return (
    <Container id={id}>
      <SectionTitle title={sectionTitle ?? ''} />
      <ContentWrapper>
        <Content>
          <ContentSection>
            <Typography variant="h3">{title}</Typography>
            <RichText document={description} withCheckmark />
          </ContentSection>

          <StyledDivider vertical={isDesktop} />

          <ContentSection>
            {message && (
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <IconButton
                  css={{ alignSelf: 'end' }}
                  iconName="reset"
                  ariaLabel="reset form"
                  variant="onlyIcon"
                  stroke={colors.blackSecondary}
                  onClick={() => {
                    setMessage(null);
                  }}
                />
                <Typography useBuiltInMargins variant="h6" color={colors.blackSecondary}>
                  {message}
                </Typography>
              </div>
            )}
            {!message && form.fields.type === 'hubspot' && (
              <>
                <Typography variant="overline" color={colors.blackSecondary}>
                  {form.fields.formTitle}
                </Typography>
                <RichText document={form.fields.formDescription} />
                <Form onSubmit={onFormSubmit} onChange={changeForm}>
                  {contentForm}
                  <ButtonWrapper>
                    <CTA
                      size="small"
                      variant="outline"
                      label={form.fields.submitText}
                      disabled={!formValid}
                    />
                  </ButtonWrapper>
                </Form>
              </>
            )}
          </ContentSection>
        </Content>
      </ContentWrapper>
    </Container>
  );
}

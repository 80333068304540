import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { spacing, colors, breakpoints } from '../../../../utils/styleguide';
import Typography from '../../text/Typography';
import Image from '../../Image';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  background-color: ${colors.grey50};
  border-radius: 2px;
  border: 1px solid ${colors.grey02};
  margin: 0 ${spacing[2]}px;

  ${breakpoints.desktop} {
    flex-direction: row;
    gap: ${spacing[5]}px;
  }
`;

const TextSectionWrapper = styled.div`
  display: flex;
  padding: ${spacing[3]}px ${spacing[4]}px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[5]}px;
  flex: 1 0 0;
  align-self: stretch;

  ${breakpoints.desktop} {
    padding: ${spacing[6]}px ${spacing[5]}px ${spacing[5]}px ${spacing[5]}px;
    flex: 1 0 0;
  }
`;

const ProfileSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  background-color: ${colors.white};
  width: auto;
  border-left: none;
  border-bottom: 1px solid ${colors.grey02};

  ${breakpoints.desktop} {
    width: 320px;
    border-left: 1px solid ${colors.grey02};
    border-bottom: none;
  }
`;

const ImageWrapper = styled.div`
  min-width: 240px;
`;

const ImageStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
type Props = {
  name?: string;
  title?: string | null;
  description?: ReactNode;
  imageUrl?: string;
  imageAlt?: string;
};
export default function PrimaryProfileCard({
  name,
  title,
  description,
  imageUrl,
  imageAlt,
}: Props) {
  return (
    <CardWrapper>
      {description && <TextSectionWrapper>{description}</TextSectionWrapper>}
      <ProfileSectionWrapper>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `1px solid ${colors.purple400}`,
            padding: `${spacing[0]}px ${spacing[3]}px`,
            margin: `${spacing[4]}px`,
            alignItems: 'start',
            gap: `${spacing[1]}px`,
            alignSelf: 'stretch',
          }}
        >
          <Typography variant="h5">{name}</Typography>
          <Typography variant="caption" color={colors.blackSecondary}>
            {title}
          </Typography>
        </div>

        {imageUrl && (
          <ImageWrapper>
            <Image maxWidth={300} lazyLoad url={imageUrl} alt={imageAlt ?? ''} css={ImageStyle} />
          </ImageWrapper>
        )}
      </ProfileSectionWrapper>
    </CardWrapper>
  );
}

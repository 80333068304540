import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeBudget_itemSkeleton } from './TypeBudget_item';
import type { TypeCategorizedRichTextSkeleton } from './TypeCategorizedRichText';
import type { TypeCategoryPageSkeleton } from './TypeCategoryPage';
import type { TypeFormSkeleton } from './TypeForm';
import type { TypeImageLinkSkeleton } from './TypeImageLink';
import type { TypeLinkSkeleton } from './TypeLink';
import type { TypeMetaHeaderSkeleton } from './TypeMetaHeader';
import type { TypeOrientedMediaSkeleton } from './TypeOrientedMedia';
import type { TypeProfileSkeleton } from './TypeProfile';

export interface TypeGenericPageTemplateFields {
  slug: EntryFieldTypes.Symbol;
  metaHeader: EntryFieldTypes.EntryLink<TypeMetaHeaderSkeleton>;
  heroSectionTitle?: EntryFieldTypes.Symbol;
  heroParagraph?: EntryFieldTypes.Symbol;
  heroCTAs?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
  heroImage?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  highlight1SectionTitle?: EntryFieldTypes.Symbol;
  highlight1Text?: EntryFieldTypes.RichText;
  highlight1Buttons?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
  highlight1Image?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  highlight2SectionTitle?: EntryFieldTypes.Symbol;
  highlight2Text?: EntryFieldTypes.RichText;
  highlight2Buttons?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
  highlight2Image?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  highlight3SectionTitle?: EntryFieldTypes.Symbol;
  highlight3Text?: EntryFieldTypes.RichText;
  highlight3Buttons?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
  highlight3Image?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  metricsSectionTitle?: EntryFieldTypes.Symbol;
  metricsList?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeBudget_itemSkeleton>>;
  expertsSectionTitle?: EntryFieldTypes.Symbol;
  expertsTitle?: EntryFieldTypes.Symbol;
  expertsText?: EntryFieldTypes.Text;
  expertsProfileList?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeProfileSkeleton>>;
  callToActionSectionTitle?: EntryFieldTypes.Symbol;
  callToActionText?: EntryFieldTypes.Symbol;
  callToActionButtons?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
  callToActionBackgroundImage?: EntryFieldTypes.AssetLink;
  highlight4SectionTitle?: EntryFieldTypes.Symbol;
  highlight4Text?: EntryFieldTypes.RichText;
  highlight4Buttons?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
  highlight4Image?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  partnerLogosTitle?: EntryFieldTypes.Symbol;
  partnerLogosImageLinks?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeImageLinkSkeleton>>;
  contactUsSectionTitle?: EntryFieldTypes.Symbol;
  contactUsTitle?: EntryFieldTypes.Symbol;
  contactUsSections?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCategorizedRichTextSkeleton | TypeFormSkeleton>
  >;
  expertsProfileTitle?: EntryFieldTypes.Symbol;
  expertsSecondaryProfileTitle?: EntryFieldTypes.Symbol;
  expertsSecondaryProfileList?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeProfileSkeleton>
  >;
  highlight1ImageFit?: EntryFieldTypes.Symbol<'contain' | 'cover'>;
  highlight2ImageFit?: EntryFieldTypes.Symbol<'contain' | 'cover'>;
  highlight3ImageFit?: EntryFieldTypes.Symbol<'contain' | 'cover'>;
  highlight4ImageFit?: EntryFieldTypes.Symbol<'contain' | 'cover'>;
  formSectionTitle?: EntryFieldTypes.Symbol;
  formSectionRichText?: EntryFieldTypes.EntryLink<TypeCategorizedRichTextSkeleton>;
  formSectionForm?: EntryFieldTypes.EntryLink<TypeFormSkeleton>;
  mapAndMetricsImpactFunds?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCategoryPageSkeleton>
  >;
}

export type TypeGenericPageTemplateSkeleton = EntrySkeletonType<
  TypeGenericPageTemplateFields,
  'genericPageTemplate'
>;
export type TypeGenericPageTemplate<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeGenericPageTemplateSkeleton, Modifiers, Locales>;

export function isTypeGenericPageTemplate<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeGenericPageTemplate<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'genericPageTemplate';
}

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, spacing } from '../../../../utils/styleguide';
import Typography from '../../text/Typography';
import Image from '../../Image';
import { memo } from 'react';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  min-width: 250px;
  max-width: 500px;
  height: 320px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  overflow: hidden;
  background-color: ${colors.purpleLight};
  border: 1px solid ${colors.grey02};
  margin: 0 ${spacing[2]}px;
`;

const ImageWrapper = styled.div`
  width: 200px;
  height: 200px;
`;

const ImageStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type Props = {
  name?: string;
  title?: string | null;
  imageUrl?: string;
  imageAlt?: string;
};

function SecondaryProfileCard({ name, title, imageUrl, imageAlt }: Props) {
  return (
    <CardWrapper>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          borderLeft: `1px solid ${colors.purple400}`,
          padding: `${spacing[0]}px ${spacing[3]}px`,
          margin: `${spacing[3]}px`,
          alignItems: 'start',
          gap: `${spacing[1]}px`,
          alignSelf: 'stretch',
        }}
      >
        <Typography variant="h6">{name}</Typography>
        <Typography variant="caption" color={colors.purple400}>
          {title}
        </Typography>
      </div>
      {imageUrl && (
        <ImageWrapper>
          <Image maxWidth={300} lazyLoad url={imageUrl} alt={imageAlt ?? ''} css={ImageStyle} />
        </ImageWrapper>
      )}
    </CardWrapper>
  );
}

export default memo(SecondaryProfileCard);

import { Document } from '@contentful/rich-text-types';
import SectionTitle from '../../SectionTitle';
import Container from '../Container';
import AccordionLayout from './AccordionLayout';
import RichText from '../../../contentful-elements/RichText/RichText';
import { breakpoints, colors, spacing } from '../../../../utils/styleguide';
import styled from '@emotion/styled';
import Typography from '../../text/Typography';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing[4]}px;
  gap: ${spacing[2]}px;
  ${breakpoints.desktop} {
    padding: 0 ${spacing[5]}px;
  }
`;

const AccordionWrapper = styled.div`
  margin-top: ${spacing[4]}px;
`;

export default function QuestionsAndAnswers({
  sectionTitle,
  title,
  preamble,
  faqSectionReferences,
  backgroundColor,
}: {
  sectionTitle?: string;
  title?: string;
  preamble?: string;
  faqSectionReferences?: Array<{
    title?: string;
    description?: Document;
  }>;
  backgroundColor?: string;
}) {
  if (!sectionTitle && !faqSectionReferences?.length) return null;

  const items = faqSectionReferences?.map((faqSectionReference) => ({
    title: faqSectionReference?.title,
    expandedContent: (
      <RichText isFaq={true} document={faqSectionReference?.description} withCheckmark />
    ),
  }));

  return (
    <Container backgroundColor={backgroundColor}>
      {sectionTitle && <SectionTitle title={sectionTitle} />}

      <TitleWrapper>
        {title && <Typography variant="h3">{title}</Typography>}
        {preamble && <Typography color={colors.blackSecondary}>{preamble}</Typography>}
      </TitleWrapper>

      <AccordionWrapper>
        <AccordionLayout items={items} isFaq />
      </AccordionWrapper>
    </Container>
  );
}

import { ReactNode } from 'react';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import styled from '@emotion/styled';
import Typography from '../text/Typography';
import Container from '../layout/Container';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[3]}px;
  padding-bottom: ${spacing[9]}px;
  padding-top: ${spacing[6]}px;

  ${breakpoints.desktop} {
    padding-top: ${spacing[8]}px;
    padding-bottom: ${spacing[9]}px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-left: ${spacing[4]}px;
  gap: ${spacing[4]}px;
  margin-top: ${spacing[4]}px;
`;

const Title = styled(Typography)`
  padding-left: ${spacing[4]}px;
  border-left: 2px solid ${colors.grey03};
  max-width: 80%;
`;

const Preamble = styled(Typography)`
  padding-left: ${spacing[4]}px;
  max-width: 80%;
`;

type Props = {
  children?: ReactNode;
  title?: string;
  preamble?: string;
};

export default function PageHero({ children, title, preamble }: Props) {
  if (!title && !preamble) return null;

  return (
    <StyledContainer disableVerticalPadding>
      <Title variant="h2" tag="h1">
        {title}
      </Title>
      {preamble && (
        <Preamble variant="body" tag="p">
          {preamble}
        </Preamble>
      )}
      {children && <ButtonWrapper>{children}</ButtonWrapper>}
    </StyledContainer>
  );
}
